:root {
    --tmr-bootstrap-border-color: #dee2e6;
    --tmr-white: $sky-light-blue;
    --tmr-table-header: #54667a;
    --tmr-row-divider-color: #3490dc;
    --tmr-stripped-row-background-color: rgba(0, 0, 0, 0.05);
}

/*-- ==============================================================
 Screen smaller than 760px and iPads.
 ============================================================== */

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    
    [data-content]:before {
        content: attr(data-content);
        text-align: left;
        word-break: break-all;
    }
    
    /* Force table to not be like tables anymore */
    .table-mobile-responsive,
    .table-mobile-responsive thead,
    .table-mobile-responsive tbody,
    .table-mobile-responsive th,
    .table-mobile-responsive td,
    .table-mobile-responsive tr {
        display: block;
    }

    .table-mobile-responsive.text-center {
        text-align: left !important;
    }
    .table-mobile-responsive caption {
        width: max-content;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .table-mobile-responsive thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .table-mobile-responsive> :not(:first-child) {
        border-top: none;
    }

    .table-mobile-responsive>:not(caption)>*>* {
        border-color: var(--tmr-bootstrap-border-color);
    }

    .table-mobile-responsive tr:not(.bg-light-blue) {
        border-bottom: 2px solid var(--tmr-row-divider-color);
    }

    /* Default layout */
    .table-mobile-responsive td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid var(--tmr-white);
        position: relative;
        padding-left: 50%;
        padding-top: 1.5rem !important;
    }

    .table-mobile-responsive td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 0;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        color: var(--tmr-table-header);
    }
    
    /* Sided layout */
    .table-mobile-responsive.table-mobile-sided> :not(:first-child) {
        border-top: none;
    }

    .table-mobile-responsive.table-mobile-sided>:not(caption)>*>* {
        border-color: var(--bs-table-border-color);
    }
    .table-mobile-responsive.table-mobile-sided td {
          /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid var(--tmr-white);
        position: relative;
        padding-left: 50%;
        padding-top: 1rem !important;
        display: flex;
        justify-content: flex-start;
        text-align: left;
    }
    .table-mobile-responsive.table-mobile-sided td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 0;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        color: var(--tmr-table-header);
        white-space: normal;
        padding-top: 1rem !important;
        text-align: right;
    }

    /* Styleless */
    .table-mobile-responsive.table-mobile-styleless tr:not(.bg-light-blue) {
        border-bottom: none !important;
    }

    /* Stripped rows */
    .table-mobile-responsive.table-mobile-striped>tbody>tr:nth-of-type(odd)>* {
        background-color: var(--tmr-stripped-row-background-color) !important;
    }
}