/* You can add global styles to this file, and also import other style files */
@import "./variables";
@import "../node_modules/font-awesome/scss/font-awesome.scss";
:root {
  --desktop-logo-width: 100%;
  --desktop-logo-height: 50px;

  --mobile-logo-width: 100%;
  --mobile-logo-height: 40px;

  --desktop-header-scrolled-vertical-padding: 25px;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: rgb(70, 70, 70);
  background-color: $black-color;
  height: calc(100% - 10px);
}
/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-30 {
  font-size: 30px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.max-w-100 {
  max-width: 100%;
}
.card {
  padding: 10px;
  background-color: $new-card-background;
  border: $new-card-border;
  border-radius: $border-radius;
}

.mat-expansion-panel-header {
  height: auto !important;
  min-height: 48px;
  padding: 0 8px !important;
}
.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  margin-right: 0px !important;
}

.mobile-tab {
  display: none !important;
}
.hide-mobile {
  display: block !important;
}

@media only screen and (max-width:1079px){
  .app-name, .user-name {
    display: none;
  }
  .wrap-mat-radio-label, .mat-tab-label-content {
    white-space: normal !important;
  }
  .mat-vertical-content-container {
    margin-left: 12px !important;
  }
  .mat-vertical-stepper-header {
    padding: 24px 0px !important;
  }
  // .mat-stepper-vertical-line::before {
  //   border-left-width: 2px !important;
  // }
  .reverse {
    flex-direction: column-reverse,
  }
  .pr-0 {
    padding-right: 0px;
  }
  .mobile-tab {
    display: block !important;
  }
  .hide-mobile {
    display: none !important;
  }
  .user-name {
    // display: none;
    display: block;
  }
  .col-sm-12 {
      flex: 0 0 auto;
      width: 100%;
  }
  .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
  }
  .pl-0 {
    padding-left: 0px;
  }
  .pt-3_5 {
    padding-top: 1rem;
  }
  .fw-header__logo {
    max-height: var(--mobile-logo-height);
    max-width: var(--mobile-logo-width);
  }
}
.fw--is-scrolled .header {
  padding: 10px !important;
}
.hidden-file {
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  width: 0px;
  height: 0px;
  border: none;
  margin: 0;
  padding: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.mat-date {
  .mat-form-field-wrapper {
      width: 100%;
      .mat-form-field-flex {
        .mat-form-field-infix {
          display: flex;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
  }
}
.loader {
  position: fixed !important;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999!important;
}
.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: $white-color;
  opacity: 0.9;
  z-index: 9999!important;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (min-device-width: 1080px) {
  .min-ht-70 {
    min-height: calc(100vh - 30vh);
  }
  .fw-header__logo {
    max-height: var(--desktop-logo-height);
    max-width: var(--desktop-logo-width);
  }
}

@import 'ngx-toastr/toastr';
::-webkit-scrollbar {
  height: 7px;
  width: 4px;
  background-color: $white-color1;
}
::-webkit-scrollbar-thumb {
  background-color: $grey;
  border-radius:5px;
}

.example-spacer {
  flex: 1 1 auto;
}

.min-ht-0 {
  min-height: unset !important;
}

.ht-0 {
  height: unset !important;
}

.fs-12 {
  font-size: 12px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

@import "./assets/theme-palette.scss";



//devleoper CSS
.min-vh-75 {
  min-height: 75vh !important;
}

.mat-icon{
  overflow: visible !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6)
}

.mat-form-field-invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}



// div{
//   border:1px solid;
// }



@media (max-width: 1079px) {
  .mobile-tab mat-tab-header {
    position: sticky;
    top: 80px;
    z-index: 55;
    background-color: white;
    border-bottom: 1px solid;
  }
}
@media (max-width: 599px) {
  .mobile-tab mat-tab-header {
    top: 70px;
  }
}
@media (max-width: 575px) {
  .mobile-tab mat-tab-header {
    top: 55px;
  }
}

mat-stepper.close-all [role="tabpanel"]{
  height: 0px; 
  visibility: hidden;
}

.bg-color-primary {
  background-color: #1a1e4b;
  color: #FFF;
}

.horz-line {
  border-bottom: solid 1px #aaa;
}

a.active{
  background-color: rgba(6, 151, 206, 0.1) !important;
}

.inline-input{
  background-color: #eee;
  border-bottom: 1px solid #666 !important;
}

.left-bar-desktop .mat-mdc-form-field-infix {
  flex: auto;
  min-width: 125px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  max-width: 8%;
}


.mat-menu-notes{
  max-width: none !important;
}

.bg-success{
  background-color: #DFF8F1 !important;
}